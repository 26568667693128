
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/views/people/employee/List.vue";
import Upsert from "@/views/people/employee/Upsert.vue";

import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";

interface ComponentData {
  employeeIdModal: number | boolean;
}
export default defineComponent({
  name: "employee-index",
  props: {
    widgetClasses: String,
  },
  components: {
    List,
    Upsert,
  },
  data(): ComponentData {
    return {
      employeeIdModal: false,
    };
  },
  methods: {
    onUpsert(employeeId: number | boolean) {
      this.employeeIdModal = employeeId;
    },
    onReset() {
      this.employeeIdModal = false;
    },
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Nhân viên", ["Đào tạo"]);
      MenuComponent.reinitialization();
    });
    return {};
  },
});


import { computed, defineComponent, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { StaffActions, StaffGetters } from "@/store/vm/people/employee/enums";
import { StepResult } from "@/store/captain/CaptainModule";
import CropperComponent from "@/../src/components/upload/cropper.vue";
import moment from "moment";

export default defineComponent({
  name: "employee-upsert",
  components: {
    cropper: CropperComponent,
  },
  props: {
    employeeId: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  methods: {
    activateCropper: function () {
      (this.$refs["avatarCropper"] as any).selectFile();
    },
    onUploadFail: function (response) {
      Swal.fire({
        position: "top-end",
        text: "Đã xảy ra lỗi upload ảnh, vui lòng thử lại sau!",
        icon: "error",
        buttonsStyling: false,
        timer: 2000,
        width: 400,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
  },
  emits: ["reset", "del"],
  setup(props, { emit }) {
    const store = useStore();
    const statusLabels = [
      {
        value: 0,
        label: "Active",
      },
      {
        value: 1,
        label: "Inactive",
      },
    ];
    const genderLabels = [
      {
        value: 0,
        label: "Nam",
      },
      {
        value: 1,
        label: "Nữ",
      },
      {
        value: 2,
        label: "Khác",
      },
    ];

    const formRef = ref<null | HTMLFormElement>(null);
    const employeeUpsertModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const defaultData = {
      id: "",
      first_name: "",
      last_name: "",
      birth_date: "01/01/2021",
      email: "string",
      phone: "string",
      phone_work: "string",
      gender: 0,
      status: 0,
      start_date: "",
      created_time: "string",
      updated_time: "string",
      // city: number;
      // district: number;
      // commune: number;
      // address: string;
      avatar: "media/avatars/150-2.jpg",
      description: "string",
      education: "string",
    };

    const formData = ref({ ...defaultData });

    const modalUpsertId = computed(() => {
      return store.state.EmployeeModule.modalUpsertId;
    });

    const rules = ref({
      first_name: [
        {
          required: true,
          message: "Yêu cầu nhập đầy đủ tên",
          trigger: "change",
        },
      ],
      last_name: [
        {
          required: true,
          message: "Yêu cầu nhập đầy đủ tên",
          trigger: "change",
        },
      ],
    });

    const employeeIdTarget = computed(() => {
      return props.employeeId;
    });

    watch(employeeIdTarget, (currentValue) => {
      if (currentValue) {
        const detail = store.getters[StaffGetters.GET_BY_ID](currentValue);
        formData.value = detail;
      } else {
        reset();
      }
    });

    const reset = () => {
      formData.value = defaultData;
      emit("reset");
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult;
          stepResult = await actionUpdate(formData.value);

          loading.value = false;
          if (stepResult.isSuccess) {
            hideModal(employeeUpsertModalRef.value);
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            reset();
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    const actionUpdate = async (par) => {
      return await store.dispatch(StaffActions.UPDATE, par).catch((e) => {
        console.log(e);
      });
    };

    const actionDel = (employeeId) => {
      Swal.fire({
        title: "Bạn muốn xóa giáo viên này?",
        text: "Sau khi xóa, dữ liệu mất vĩnh viễn",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Xóa",
        cancelButtonText: "Không",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await store
            .dispatch(StaffActions.DELETE, employeeId)
            .catch((e) => {
              console.log(e);
            });
          if (resDel.isSuccess) {
            hideModal(employeeUpsertModalRef.value);
          }
        }
      });
    };

    const onUploadSuccess = (imageUrl) => {
      formData.value.avatar = imageUrl;
    };

    return {
      statusLabels,
      genderLabels,
      modalUpsertId,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      employeeUpsertModalRef,
      employeeIdTarget,
      actionDel,
      onUploadSuccess,
      moment,
    };
  },
});
